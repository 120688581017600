import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'

const ContributiPubbliciPage = () => {
  return (
    <Layout>
      <SEO
        description="F2.net contributi pubblici percepiti ai sensi della legge n.124"
        keywords={[
          `F2.net`,
          `React`,
          `Redux`,
          `Next.js`,
          `Gatbsby`,
          `Node.js`,
          `mysql`,
          `postgresql`,
        ]}
        title="F2.net contributi pubblici percepiti"
      />

      <div className="flex flex-col justify-center max-w-4xl mx-auto px-6 pt-20 pb-10">
        <h1 className="text-3xl sm:text-3xl text-center font-black leading-tight mb-3">
          F2. net engineering s.r.l.
          <br />
          Dettaglio contributi percepiti nell'anno 2021
        </h1>
        <p className="text-center leading-relaxed text-gray-700">
          Ai sensi dell'art. 1, comma 125, della Legge 4 Agosto 2017, n.124,
          sostituito dall’art.35 del D.L. n.34 del 30 aprile 2019, in
          ottemperanza all'obbligo di trasparenza, si riportano di seguito gli
          estremi degli importi relativi a sovvenzioni, contributi, incarichi
          retribuiti e comunque vantaggi economici di qualunque genere da
          pubbliche amministrazioni ricevuti nel periodo considerato, per un
          importo superiore a Euro 10.000,00.
        </p>
      </div>

      <section className="px-6 pb-20">
        <div className="flex flex-row flex-wrap max-w-sm sm:max-w-xl lg:max-w-5xl mx-auto justify-center sm:justify-between">
          <table className="w-full">
            <thead>
              <tr>
                <td className="py-2 px-4 border font-bold">
                  Soggetto erogante
                </td>
                <td className="py-2 px-4 border font-bold">
                  Valore contributo 2021
                </td>
                <td className="py-2 px-4 border font-bold">Stato pratica</td>
                <td className="py-2 px-4 border font-bold">
                  Riferimento normativo
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-2 px-4 border">Stato italiano</td>
                <td className="py-2 px-4 border text-right">€ 14.030,00 (1)</td>
                <td className="py-2 px-4 border">Compensato 1/3</td>
                <td className="py-2 px-4 border">
                  Ricerca & sviluppo L. 190 e s.m. 2021
                </td>
              </tr>
              <tr>
                <td className="py-2 px-4 border">Stato italiano</td>
                <td className="py-2 px-4 border text-right">€ 19.684,00 (1)</td>
                <td className="py-2 px-4 border">Compensato</td>
                <td className="py-2 px-4 border">
                  Formazione 4.0 L.205/2017 art.1 commi 45-56 e s.m. 2021
                </td>
              </tr>
              <tr>
                <td className="py-2 px-4 border">Stato italiano</td>
                <td className="py-2 px-4 border text-right">€ 3.685,00</td>
                <td className="py-2 px-4 border">Compensato</td>
                <td className="py-2 px-4 border">
                  Decreto Sostegni Bis D.L.73/2021
                </td>
              </tr>
              <tr>
                <td className="py-2 px-4 border border-t-2 uppercase font-bold">
                  Totale
                </td>
                <td className="py-2 px-4 border border-t-2 text-right font-bold">
                  € 37.399,00
                </td>
                <td className="py-2 px-4 border border-t-2"></td>
                <td className="py-2 px-4 border border-t-2"></td>
              </tr>
            </tbody>
          </table>
          <div className='mt-2 text-sm'>
            (1) comprensivo del credito spettante per l’attestazione rilasciata
            dal revisore legale.
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContributiPubbliciPage
